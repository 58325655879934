// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chalk-it-page-js": () => import("./../src/pages/chalk-it-page.js" /* webpackChunkName: "component---src-pages-chalk-it-page-js" */),
  "component---src-pages-contacts-js": () => import("./../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-product-category-chalk-it-js": () => import("./../src/pages/product-category/chalk-it.js" /* webpackChunkName: "component---src-pages-product-category-chalk-it-js" */),
  "component---src-pages-product-category-impregnating-agents-js": () => import("./../src/pages/product-category/impregnating-agents.js" /* webpackChunkName: "component---src-pages-product-category-impregnating-agents-js" */),
  "component---src-pages-product-category-paints-js": () => import("./../src/pages/product-category/paints.js" /* webpackChunkName: "component---src-pages-product-category-paints-js" */),
  "component---src-pages-product-category-wood-stains-and-varnishes-js": () => import("./../src/pages/product-category/wood-stains-and-varnishes.js" /* webpackChunkName: "component---src-pages-product-category-wood-stains-and-varnishes-js" */),
  "component---src-pages-product-chalk-it-farba-colors-js": () => import("./../src/pages/product/chalk-it-farba-colors.js" /* webpackChunkName: "component---src-pages-product-chalk-it-farba-colors-js" */),
  "component---src-pages-product-chalk-it-grunt-js": () => import("./../src/pages/product/chalk-it-grunt.js" /* webpackChunkName: "component---src-pages-product-chalk-it-grunt-js" */),
  "component---src-pages-product-chalk-it-lack-js": () => import("./../src/pages/product/chalk-it-lack.js" /* webpackChunkName: "component---src-pages-product-chalk-it-lack-js" */),
  "component---src-pages-product-chalk-it-lack-matovy-js": () => import("./../src/pages/product/chalk-it-lack-matovy.js" /* webpackChunkName: "component---src-pages-product-chalk-it-lack-matovy-js" */),
  "component---src-pages-product-chalk-it-pure-white-js": () => import("./../src/pages/product/chalk-it-pure-white.js" /* webpackChunkName: "component---src-pages-product-chalk-it-pure-white-js" */),
  "component---src-pages-product-chalk-it-wosk-js": () => import("./../src/pages/product/chalk-it-wosk.js" /* webpackChunkName: "component---src-pages-product-chalk-it-wosk-js" */),
  "component---src-pages-product-luxdecor-acrylic-enamel-glossy-js": () => import("./../src/pages/product/luxdecor-acrylic-enamel-glossy.js" /* webpackChunkName: "component---src-pages-product-luxdecor-acrylic-enamel-glossy-js" */),
  "component---src-pages-product-luxdecor-acrylic-enamel-js": () => import("./../src/pages/product/luxdecor-acrylic-enamel.js" /* webpackChunkName: "component---src-pages-product-luxdecor-acrylic-enamel-js" */),
  "component---src-pages-product-luxdecor-impregnating-agent-js": () => import("./../src/pages/product/luxdecor-impregnating-agent.js" /* webpackChunkName: "component---src-pages-product-luxdecor-impregnating-agent-js" */),
  "component---src-pages-product-luxdecor-njord-js": () => import("./../src/pages/product/luxdecor-njord.js" /* webpackChunkName: "component---src-pages-product-luxdecor-njord-js" */),
  "component---src-pages-product-luxdecor-njord-separating-primer-js": () => import("./../src/pages/product/luxdecor-njord-separating-primer.js" /* webpackChunkName: "component---src-pages-product-luxdecor-njord-separating-primer-js" */),
  "component---src-pages-product-luxdecor-stain-varnish-js": () => import("./../src/pages/product/luxdecor-stain-varnish.js" /* webpackChunkName: "component---src-pages-product-luxdecor-stain-varnish-js" */),
  "component---src-pages-product-wood-varnish-glossy-js": () => import("./../src/pages/product/wood-varnish-glossy.js" /* webpackChunkName: "component---src-pages-product-wood-varnish-glossy-js" */),
  "component---src-pages-product-wood-varnish-satin-js": () => import("./../src/pages/product/wood-varnish-satin.js" /* webpackChunkName: "component---src-pages-product-wood-varnish-satin-js" */),
  "component---src-pages-products-js": () => import("./../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-where-to-buy-js": () => import("./../src/pages/where-to-buy.js" /* webpackChunkName: "component---src-pages-where-to-buy-js" */)
}

